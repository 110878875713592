import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import logo from '../assets/img/vipsports247.jpg';
import logos from '../assets/img/logos.webp';

export default class header extends Component {
    render() {
        return (
            <div className='header'>
                <div className="row g-0">
                    <div className="col-xl-3">
                        <Link to="/home">
                            <img className="img-fluid" src={logo} alt="logo"></img>
                        </Link>
                    </div>
                    <div className="d-none d-lg-block col-lg-5 pt-4">
                        <div className="col-lg-5 d-flex justify-content-center align-items-center">
                            <form action="https://wager.terrabet.net/Qubic/SecurityPage.php" method="post" name="client login" >
                                <div className="row p-2 text-white textShadow">
                                    <div className="col-4 text-center">
                                        <input name="customerID" type="text" class="form-control" placeholder="USER" aria-label="USER" autoFocus />
                                    </div>
                                    <div className="col-4 text-center">
                                        <input type="password" name="Password" placeholder="Password" class="form-control" />
                                        <input type="hidden" name="action" value="1" />
                                    </div>
                                    <div className="col-4 text-center">
                                        <button type="submit" className="btn btn_login">Login</button>
                                        <input type="hidden" name="action" value="1" />
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="d-sm-block d-lg-none col-lg-5">
                        <div className="col-lg-5 d-flex justify-content-center align-items-center px-lg-2">
                            <form action="https://wager.terrabet.net/mQubic/SecurityPage.php" method="post" name="client login" >
                                <div className="row p-2 text-white textShadow">
                                    <div className="col-4 text-center">
                                        <input name="customerID" type="text" class="form-control" placeholder="USER" aria-label="USER" autoFocus />
                                    </div>
                                    <div className="col-4 text-center">
                                        <input type="password" name="Password" placeholder="Password" class="form-control" />
                                        <input type="hidden" name="action" value="1" />
                                    </div>
                                    <div className="col-4 text-center">
                                        <button type="submit" className="btn btn_login">Login</button>
                                        <input type="hidden" name="action" value="1" />
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="col-lg-4 d-flex justify-content-center align-items-center p-2 p-md-0">
                        <img className='img-fluid' src={logos} alt="logos of sports"></img>
                    </div>
                </div>
            </div>
        )
    }
}
